* {
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth; 
  font-family: 'Noto Sans', sans-serif;
}

/* font-family: 'Noto Sans', sans-serif;
font-family: 'Nunito', sans-serif; */
  
/* 
  Headings/Sub-Headings/Paragraphs/ Fonts Standard Spacing Fonts along with media queries,
*/

.font-noto
{
  font-family: 'Noto Sans', sans-serif!important;
 
}
.font-Open-Sans
{
  /* font-family: 'Open Sans', sans-serif; */
 
}

.standard-margin-space {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.standard-padding-space {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.body-heading, .hero-section-heading { 
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: 'Noto Sans', sans-serif;
}
.body-heading span, .hero-section-heading span{ 
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Noto Sans', sans-serif;
}

.body-heading {
   color: #ffffff; 
   font-size: 43px; 
   font-weight: 700;
   font-family: 'Noto Sans', sans-serif;
  }

.hero-section-heading  
{ 
  font-size: 60px;
  font-weight: 700;
  color: white; 
  font-family: 'Noto Sans', sans-serif;
  /* letter-spacing: 4px;  */
}
.hero-section-heading span {font-family: 'Noto Sans', sans-serif; }
  
.body-paragraph { 
  font-weight: 400;
  font-size: 16px;
  color: #d8d8d8;
  font-family: 'Nunito', sans-serif;
}

  

.genral-btn{ 
  padding: 0.5rem 2rem;
  font-size: 14px;
  font-weight: 400;
  color: white;
  border-radius: 20px;
  filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.15));
  background-image: linear-gradient(78deg, #3ab6e7 0%, #238ab2 100%);
  border: none;
  transition: .3s;
  font-family: 'Noto Sans', sans-serif;
}
.genral-btn:hover{ 
  background-image: linear-gradient(78deg, #3ab6e7 30%, #238ab2 100%);
}

.hero-sub-heading
{
  font-size: 25px;
  font-weight: 600;
  color: white;
  font-family: 'Noto Sans', sans-serif;
}
.sub-heading
{
  font-size: 25px;
  font-weight: 600;
  color: #39b4e4;
  text-transform: uppercase;
  font-family: 'Noto Sans', sans-serif;

}

.genral-btn span {
  margin-right: .4rem;
}

@media (max-width: 1250px) {
  .body-heading  {
    font-size: 35px;
  }

  .hero-section-heading {
    font-size: 45px;
  }
}

@media (max-width: 460px) {
  .body-heading  {
    font-size: 30px;
  }
 
  .hero-section-heading  {
    font-size: 35px;
  }

  .body-paragraph {
    font-size: 15px;
  }
}

@media (max-width: 380px) {
  .body-heading  {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 32px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
    /* font-size: 12px;  */
  } 
}


/* Width, color, and radius for the track */
::-webkit-scrollbar {
  width: 12px;
}

::-moz-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #238ab2;
  transition: .3s;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background: #238ab2;
  transition: .3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #238ab2;
}

::-moz-scrollbar-thumb:hover {
  background: #238ab2;
}

/* Track */
::-webkit-scrollbar-track {
  transition: .3s;
  background: #9dcee1;
}

::-moz-scrollbar-track {
  transition: .3s;
  background: #9dcee1;
}
/* Track on hover */
::-webkit-scrollbar-track:hover {
  transition: .3s;
  background: #9dcee1;
}

::-moz-scrollbar-track:hover {
  transition: .3s;
  background: #9dcee1;
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background: #238ab2;
}

::-moz-scrollbar-thumb:active {
  background: #238ab2;
}

/* Track when dragging */
::-webkit-scrollbar-track:active {
  transition: .3s;
  background: #9dcee1;
}

::-moz-scrollbar-track:active {
  transition: .3s;
  background: #9dcee1;
}
