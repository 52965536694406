.discover-container
{
}
.discover-container .text-content h3
{
    font-size: 3rem;
}
.discover-container .text-content .body-paragraph
{
    width: 80%;
    margin: auto;
}

.discover-container .discover-card-box
{
    background:transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    gap: 15px;
    padding:15px 10px;
    transition: .3s;
}
.discover-container .discover-card-box:hover
{
    background: rgba(255, 255, 255, 0.079);
    cursor: pointer;
}

.discover-container .discover-card-box .card-icon img{
    width: 60px;
    height: 60px;
}
.discover-container .discover-card-box .card-icon{
   
}

.discover-container .discover-card-box .card-title
{
    color :white;
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
}

.discover-container .discover-card-box .card-content
{
    color :#949494;
    font-size: .9rem;
}

.discover-container .discover-card-box .read-more-anchor
{
    font-size: 16px;
    line-height: 30px;
    text-decoration: underline;
    color: #d8d8d8;
    font-weight: 400;
    transition: .3s;

}


.discover-container .discover-card-box .read-more-anchor:hover{
    cursor: pointer;
    color: #d8d8d8af;
}

.discover-container .discover-card-box .btn-icon{
    height: 13px;
    width: 13px;
}


