.drop-down-content-section
{

}


.drop-down-content-section .dropdown-box
{
    cursor:pointer;
    width: 100%;
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.04));
    padding: 30px 30px;
    border-radius: 15px;
    background-color: transparent;
    border: 2px solid #145b77;
}

.drop-down-content-section .line-seprator{
  width: 77%;
  border: 1px solid rgba(128, 128, 128, 0.199);
  margin: auto;

}

.drop-down-content-section .dropdown-box .box-head .title{
    font-weight: 600;
    font-size: 16px;
}

.drop-down-content-section .eye-icon
{
    width: 15px;
    height: 15px;
}

@keyframes bottomBoxOpen {
    from{
        display: none;
        transform: translateY(-3px);
    }
    to{
       display: block;
       transform: translateY(0px);
    }
}
@keyframes bottomBoxClose {
    from{
        display: block;
        transform: translateY(0px);
    }
    to{
        transform: translateY(-3px);
       display: none;
    }
}