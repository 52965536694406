.common_hero_section {
  color: black;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
 
  width: 100%;
  height: 40rem;
  z-index: 0;
}

/* .common_hero_section .bg-effect
{
  background: rgba(167, 166, 166, 0.367);
  background: linear-gradient(0deg, rgba(91, 91, 91, 0.445) 0%, rgba(76, 75, 75, 0.377) 100%);
} */
.common_hero_section .bg-effect
{
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient( hsla(220, 72%, 14%, 0.285), hsla(220, 71%, 14%, 0.051),#0818392f,#09193A);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index:-1 ;
}

.common_hero_section .hero-container
{
  border-radius: 20px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.common_hero_section .wrapper
{
  
  border-radius: 20px;
  height: 100%;
}

.bg-video
{
  position: relative;
  width: 100%;
  height: 100vh;
  /* overflow: hidden; */
  background-color: rgba(255, 255, 255, 0.536);
}

.bg-video .bg-video-mp{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.common_hero_section .hero_content_wrapper {
  display: flex;
  padding-left: 0%;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 85%;
}

.common_hero_section .hero_content_wrapper .hero-section-heading,
.common_hero_section .hero_content_wrapper .body-paragraph {
  text-align: center;
}

.common_hero_section .hero_content_wrapper .body-paragraph
{
  width: 80%;
  max-width: 850px;
  color: white;
}

.common_hero_section .hero_content_wrapper .hero-btn
{
  padding: 0.6rem 2.3rem;
  font-size: 14px;
  background: transparent;
  border: 1px solid white;
  font-weight: 700;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 29px;
  transition: .3s;
}
.common_hero_section .hero_content_wrapper .hero-btn:hover
{
  background: white;
  border: 1px solid white;
  color: #3AB5E6;
}
.common_hero_section .heading-color-change
{
  color: #3AB5E6;

}
.common_hero_section .service-form
{
  background-color: rgba(164, 164, 164, 0.262);
  backdrop-filter: blur(9px);
  border-radius: 10px;
  max-width: 450px;
  margin-left: auto;
}
.common_hero_section .service-form .service-from-btn
{
  width: 100%;
  margin-top: 30px;
}
.common_hero_section .service-form input
{
  border: none;
  border-bottom: 1px solid rgb(255, 254, 254);
  background:transparent;
  margin-top: 20px;
  color:white;
  padding: 10px 0;

}
.common_hero_section .service-form input::placeholder
{
  color:#f0f0f0;

}
.common_hero_section .service-form input:focus
{
  outline: none;
}

.common_hero_section .hero-text-content .body-paragraph 
{
  /* width: 50%; */
  /* text-align: center; */
}
@media(max-width :992px)
{
  .common_hero_section .service-form
  {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  
}
@media(max-width :760px)
{
  .h-cus-auto
  {
    height: auto!important;
  }
  
}


@media(max-width : 660px)
{
  .common_hero_section .hero-text-content .body-paragraph 
  {
    width : 100%;
  }
}

@media(max-width : 400px)
{
  .common_hero_section .hero_content_wrapper .hero-btn
  {
    padding: 0.6rem 1.8rem;
    font-size: 15px;
  }
}