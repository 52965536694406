.MarketingCard-container
{

}

.MarketingCard-container .card-box
{
    background:#163556;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 26px;
    justify-content: center;
    gap: 10px;
    height: 100%;
    padding:40px 20px;
    transition: .3s;
}
.MarketingCard-container .card-box img
{
    height: 80px;
    width: 80px;
}
.MarketingCard-container .card-box:hover
{
    background: rgba(255, 255, 255, 0.079);
    cursor: pointer;
}