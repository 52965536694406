.discover-image-container
{
}
.discover-image-container .text-content h3
{
    font-size: 3rem;
}
.discover-image-container .text-content .body-paragraph
{
    width: 80%;
    margin: auto;
}

.discover-image-container .discover-card-box
{
    background:#163556;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 26px;
    justify-content: space-between;
    gap: 15px;
    padding:15px 15px;
    transition: .3s;
}
.discover-image-container .discover-card-box:hover
{
    background: rgba(255, 255, 255, 0.079);
    cursor: pointer;
}

.discover-image-container .discover-card-box img{
    width: 100%;
    /* height: 60px; */
}
.discover-image-container .discover-card-box .card-icon{
   
}

.discover-image-container .discover-card-box .card-title
{
    color :white;
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
}

.discover-image-container .discover-card-box .card-content
{
    color :#949494;
    font-size: .9rem;
}

.discover-image-container .discover-card-box .read-more-anchor
{
    font-size: 16px;
    line-height: 30px;
    text-decoration: underline;
    color: #d8d8d8;
    font-weight: 400;
    transition: .3s;

}


.discover-image-container .discover-card-box .read-more-anchor:hover{
    cursor: pointer;
    color: #d8d8d8af;
}

.discover-image-container .discover-card-box .btn-icon{
    height: 13px;
    width: 13px;
}


