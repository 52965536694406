.Testimonials-container
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Testimonials-container .testimonial-card
{
    cursor: pointer;
}
.Testimonials-container .testimonial-card .arrow-img
{
    width:45px;
}


.Testimonials-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.Testimonials-container .profile-info
{
    
}


.Testimonials-container .testimonial-card-line-seprator
{
    border: 1px solid #c8c8c8;
    width: 100%;
    height: 0;
    
}
.Testimonials-container .profile-info .name
{
    color: #141414;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
}

.Testimonials-container .btn-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}


.Testimonials-container .swiper-pagination
{
    width: fit-content!important;
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet
{
    background-color: white;
    opacity: 1;
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet-active
{
    background-color: #37B0E0;
    opacity: 1;
}


@media(max-width: 750px)
{
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 95%;
        margin: auto;
    }
}
@media(max-width: 576px)
{
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 100%;
        margin: auto;
    }
}