.text-content-section
{
    background:transparent;
}

.text-content-section .text-content{
  /* background : rgb(235, 220, 227);
  background: radial-gradient(circle, rgb(243, 231, 236) 0%,rgb(249, 243, 246) 35%, rgba(252,252,252,1) 56%, rgba(255,255,255,1) 100%); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.text-content-section .container-space
{
  margin: auto;
}

.text-content-section .paddLeft
{
    padding-left: 5%;
}

.text-content-section .line-seprator
{
    border: 1px solid #c1c0c0;
    width: 100%;
    margin: auto;

}

.text-content-section .text-btn
{
  padding: 0.6rem 2.2rem;
  font-size: 14px;
  background: transparent;
  border: 1px solid #8740A5;
  color: #8740A5;
  font-weight: 700;
  font-family: "Open Sans";
  border-radius: 25px;
  text-transform: uppercase;
  transition: .3s;
}
.text-content-section .text-btn:hover
{
  background: #8740A5;
  border: 1px solid #8740A5;
  color: white;
}

.text-content-section .dropdown-box
{
    cursor:pointer;
    width: 100%;
    padding: 10px 0;
    background-color: transparent;
}

.text-content-section .line-seprator{
  width: 98%;
  border: 1px solid rgba(128, 128, 128, 0.571);
  margin: auto;

}

.text-content-section .dropdown-box .box-head .title{
    font-weight: 600;
    font-size: 18px;
}

.text-content-section .eye-icon
{
    width: 20px;
    height: 20px;
}

@keyframes bottomBoxOpen {
    from{
        display: none;
        transform: translateY(-3px);
    }
    to{
       display: block;
       transform: translateY(0px);
    }
}
@keyframes bottomBoxClose {
    from{
        display: block;
        transform: translateY(0px);
    }
    to{
        transform: translateY(-3px);
       display: none;
    }
}