.SideBar-container
{
  background-color: rgba(144, 144, 144, 0.262);
  backdrop-filter: blur(13px);
    position: absolute;
    top: 90px;
    right: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out , visibility 0.3s ease-in-out;
    border-radius: 12px;
    z-index: 9999;
}
.SideBar-container .text-content
{
  background-color: #37b0e0;
  padding: 25px 15px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.SideBar-container .text-content h4
{
  font-weight: 600;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  margin-top: 5px;
  color: white;
}
.SideBar-container .text-content h6
{
  color: white;
   font-weight: 600;
   font-size: 16px;
   font-family: 'Nunito', sans-serif;
}
.SideBar-container
{
    
    position: absolute;
    top: 80px;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 12px;
    z-index: 100;
}

.SideBar-container .content {
    padding: 15px;
    
    background-color: transparent;
    border-radius: 10px;
    
    position: relative;
    /* z-index: 10; */
  }
.SideBar-container .content input{
    border: 1px solid rgba(255, 255, 255, 0.568);
    padding:8px 5px ;
    padding-left: 15px;
    border-radius: 10px;
    background-color: transparent;
    color: white;
  }
.SideBar-container .content input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.SideBar-container .content input:focus{
   outline: none;
  }
.SideBar-container .inp-tit {
    margin-top: 12px;
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    color: white;
  }

.hide{
    opacity: 0!important;
    visibility: hidden;
  }

.show{
    opacity: 1!important;
    visibility: visible;
  }